import React from "react"

import socialImage from "../../static/DECAZES-social-share.jpg"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Historique from "../components/Historique"

const HistoriquePage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Historique" image={socialImage} />
      <Historique data={data} />
    </Layout>
  )
}

export default HistoriquePage

export const query = graphql`
  query getHistoricData {
    file(relativePath: { eq: "elie-decazes.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
