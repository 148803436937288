import React from "react"
import { useSpring, animated } from "react-spring"
import Img from "gatsby-image"

import "./styles.scss"

const Historique = ({ data }) => {
  const imageprops = useSpring({
    to: { opacity: 1, transform: "translateY(0)" },
    from: { opacity: 0, transform: "translateY(10%)" },
    config: { mass: 1, tension: 240, friction: 38, precision: 0.00001 },
    delay: 280,
  })
  const textprops = useSpring({
    to: { opacity: 1, transform: "translateY(0)" },
    from: { opacity: 0, transform: "translateY(5%)" },
    config: { mass: 1, tension: 240, friction: 38, precision: 0.00001 },
    delay: 100,
  })
  return (
    <div className="historique">
      <animated.div style={textprops} className="historique-text">
        <h1>Historique</h1>
        <h2>Élie, duc Decazes et de Glücksbierg</h2>
        <h3>
          Homme politique français (Saint-Martin-de-Laye, 1780 — Paris, 1860).
        </h3>
        <p>
          Né en 1780 à Saint-Martin-de-Laye en Gironde, il étudia le droit pour
          devenir juge au tribunal de la Seine en 1806. Il est nommé ensuite
          conseiller de Louis Bonaparte en 1807, et devient avocat-conseil à la
          cour de l’appel de Paris en 1811. Il fut en même temps attaché comme
          conseil au jeune roi de Hollande, Louis, et à l’impératrice mère.
        </p>
        <p>
          Immédiatement après la chute de l’Empire, il n’en accueillit pas moins
          la Restauration avec empressement, refusa de signer une adresse de
          félicitation à Napoléon Ier après son retour de l’île d’Elbe et se
          déclare royaliste, et reste fidèle aux Bourbons pendant les
          Cent-Jours. Par l’entremise de Joseph-Dominique Louis dit baron Louis,
          il peut rencontrer le roi Louis XVIII pendant cette période, qui le
          récompense pour sa fidélité en le nommant préfet de police de Paris le
          7 juillet 1815. Bien que Louis XVIII eût coutume de ne s’entretenir de
          politique qu’avec ses ministres, il obtient l’autorisation de faire
          ses rapports directement au roi, sans passer par Fouché (un régicide
          !).
        </p>
        <p>
          Le roi se prend d’une forte amitié pour lui jusqu’à l’appeler « mon
          fils ». Il fut nommé par Louis XVIII préfet de police le 7 juillet
          1815, et bientôt le 24 septembre, nommé ministre de la Police dans le
          ministère du duc de Richelieu, en remplacement de Fouché. Il prit sur
          ce prince un grand ascendant, qu’il devait à l’aménité de ses manières
          et au charme de son esprit tout autant qu’à l’accord des vues;
          s’opposa de toutes ses forces aux excès de la réaction
          ultra-royaliste.
        </p>
        <p>
          Entre temps, il avait été élu député de la Seine en août 1815,
          défendant un royalisme modéré par ses fonctions de député et de
          ministre, sa formule étant « royaliser la France et nationaliser la
          monarchie ». Les royalistes modérés étant en minorité dans la «
          Chambre introuvable » de 1815 face aux ultras, Decazes persuada Louis
          XVIII de dissoudre l’assemblée des députés. Il fit rendre dans ce but
          la célèbre ordonnance du 5 septembre 1816, qui dissolvait la Chambre
          introuvable. Les élections d’octobre 1816 lui donnèrent une majorité
          plus proche des désirs du ministère. Decazes joua un rôle essentiel
          dans le gouvernement durant les quatre années suivantes .
        </p>
        <p>
          Comme ministre de la Police, il réprima les insurrections provoquées
          par les royalistes ultras (la terreur blanche). Il fit abolir la
          plupart des lois d’exception et modifier la loi électorale dans un
          sens libéral en abaissant l’âge et le cens (1817). Après la démission
          du duc de Richelieu en 1818, il prit la direction effective du
          gouvernement du général-marquis Dessolle. Le ministère Dessolle,
          comprenant Decazes à l’Intérieur, le baron Louis aux Finances, et le
          maréchal Gouvion-Saint-Cyr à la Guerre, était entièrement libéral ; et
          son premier acte fut de supprimer le ministère de la Police, car
          Decazes avait senti son incompatibilité avec le régime de la Liberté.
          Ses réformes rencontrèrent une vive hostilité à la chambre des pairs,
          où les ultras étaient majoritaires, dont il brisa l’opposition et
          qu’il contourna en incitant le roi à créer soixante nouveaux pairs
          libéraux (5 mars 1819); mais devint par là en butte aux plus violentes
          attaques des royalistes.
        </p>
        <p>
          Il fit alors passer les lois sur la presse, supprimant la censure. Par
          la réorganisation des finances, la protection de l’industrie et la
          mise en œuvre de grands travaux publics, la France retrouva sa
          prospérité économique, et le ministère devint populaire.
        </p>
        <p>
          Mais les grandes puissances de l’alliance observaient la montée du
          libéralisme en France avec une inquiétude croissante. Metternich
          déplora particulièrement la « faiblesse » du ministère, et quand en
          1819 les élections confirmèrent cette tendance, notamment avec
          l’élection du célèbre abbé Grégoire, les gouvernements européens
          réfléchirent à mettre en œuvre les clauses secrètes du Traité
          d’Aix-la-Chapelle (1818). C’est cette menace d’interposition
          étrangère, plutôt que la clameur des « Ultras », qui obliga Louis
          XVIII à une modification de la loi électorale afin de rendre
          impossible à l’avenir un « scandale » tel que l’élection de l’abbé
          Grégoire.
        </p>
        <p>
          Il recourut pour se maintenir à un système de bascule qui laissait
          dominer alternativement chaque parti : il consentit même à abandonner
          sa propre loi électorale et à constituer un ministère royaliste :
          Dessolles et le baron Louis, refusèrent de s’embarquer dans cette
          politique et démissionnèrent ; Decazes prit la tête du nouveau
          ministère, en tant que président du conseil (19 novembre 1819).
          L’exclusion de Grégoire de la chambre et des changements de la Charte
          rendirent les libéraux hostiles sans pour autant réconcilier les «
          Ultras ». Les nouvelles de la révolution espagnole de janvier 1820,
          provoquée par la politique idiote et criminelle du favori royal,
          aggravèrent encore la situation. Decazes fut dénoncé comme un nouveau
          Sejanus, le Catilina moderne ; et quand le 13 février, le duc de Berry
          fut assassiné, on l’accusa d’être, directement ou indirectement,
          complice du crime par sa complaisance du roi envers les libéraux.
        </p>
        <p>
          Decazes, prévoyant l’orage, remit immédiatement sa démission au roi,
          qui la refusa dans un premier temps, en lui disant : « ils attaqueront
          non votre système, mon cher fils, mais le mien ».
        </p>
        <p>
          Finalement contraint à la démission, Decazes est élevé au rang de duc,
          et se retire honorablement comme ambassadeur en Grande-Bretagne.
          Chateaubriand, écrira : « Le pied lui a glissé dans le sang ».
        </p>
        <p>
          Ceci mit fin à sa carrière dans l’exécutif. Rappelé de ce poste sous
          le ministère Villèle en décembre 1821 il prend place à la Chambre des
          pairs, où il continua à prôner une politique libérale. Il adhéra après
          1830 à la monarchie de juillet. Il fut nommé en 1834 grand
          référendaire de la Chambre des Pairs. Il quitta entièrement les
          affaires et la vie politique à partir de 1848.
        </p>
        <p>
          Indépendamment de son rôle politique, Decazes signala son
          administration par des mesures favorables aux arts, à l’agriculture et
          à l’industrie : il rétablit en 1819 l’exposition quinquennale des
          produits de l’industrie. En 1826, il fonde avec sa fortune personnelle
          une société pour développer le charbon et le fer dans l’Aveyron. La
          ville qui abritait les ouvriers prit le nom de Decazeville en 1829.
        </p>
        <p>Le roi du Danemark l’avait fait duc de Glücksbierg en 1818.</p>
        <p>(source: Wikipédia)</p>
      </animated.div>
      <animated.div style={imageprops} className="historique-image">
        <div className="historique-image-picture">
          <Img
            loading="auto"
            fluid={data.file.childImageSharp.fluid}
            alt="Elie Decazes"
          />
        </div>
        <p>Gravure représentant Élie, duc Decazes et de Glücksbierg.</p>
      </animated.div>
    </div>
  )
}
export default Historique
